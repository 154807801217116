.chat-history {
    flex: 1;
    padding: 20px;
    background-color: #f8f9fa;
    overflow-y: auto;
    box-sizing: border-box;
  }
  
  .history-message {
    padding: 5px 5px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    align-content: center;
    gap: 5px;
  }
  
  .messageText {
    white-space: pre-line;
  }

  .active {
    background-color: #1D8A50;
    color: #fff;
    border-radius: 10px;
  }
  h2 {
    margin-top: 0;
  }
  