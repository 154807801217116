.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.swal2-container {
  z-index: 1500 !important;
}
h1, h2, h3, h4, h5, h6 {
  color: #333333;
}

/* Common styles for items */
.item {
  /* Add styling for each item */
  border: 0px solid #ccc;
  padding: 0px;
  text-align: center;
  /* Add any other styles you want */
}

.vc-welcome-div {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.col-50 {
  width: 50%;
}
/* Desktop view - 4 items per row */
@media (min-width: 900px) {
  .item-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Create 4 columns */
    gap: 20px; /* Adjust the gap between items */
  }
  .annoucement-container {
    padding: 0 40px;
  }
}

.row {
  display: flex;
  flex-direction: row;
}

.col-rs-3 {
  width: 25%;
}

.col-rs-6 {
  width: 50%;
}
.col-rs-9 {
  width: 75%;
}

.col-chat-group {
  width: 35%;
}

.col-chat-message {
  width: 65%;
}

/* Mobile view - 2 items per row */
@media (max-width: 900px) {
  .item-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Create 2 columns */
    gap: 10px; /* Adjust the gap between items */
  }
  .col-rs-3, .col-rs-6, .col-rs-9, .col-chat-group, .col-chat-message {
    width: 100%;
  }
}

@media (max-width: 460px) {
  .item-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* Create 1 columns */
    gap: 10px; /* Adjust the gap between items */
  }
  .col-rs-3, .col-rs-6, .col-rs-9, .col-chat-group, .col-chat-message {
    width: 100%;
  }
}

.swal2-confirm {
  background-color: #1D8A50 !important;
  box-shadow: none !important;
}

.input {
  border-radius: 50px;
  background-color: #ffffff;
  width: 100%;
  height: 50px;
  border: 0;
  margin-bottom: 20px;
  padding: 15px;
  box-sizing: border-box;
  outline: none;
}

.input:focus {
  border: 1px solid #1D8A50 !important;
}

.page-title {
  margin: 0;
  padding-bottom: 20px;
}
.slick-arrow {
  background-color: #1D8A50 !important;
  border-radius: 50%;
  padding: 0;
}
.slick-next {

}

.slick-dots {
  font-size: 20px !important;
}

.slick-active {
  color: #D12B1E !important;
}

.link {
  color: #1D8A50 !important;
  text-decoration: none
}
.link:hover {
  text-decoration: underline
}

.mobile-only {
  display: none;
}

.desktop-only {
  display: block;
}
@media only screen and (max-width: 900px) {
  .mobile-only {
    display: block;
  };
  .desktop-only {
    display: none;
  }
  .left-login-container {
    display: none !important;
  }
  .right-login-container {
    width: 100% !important;
  }
  .form-wrapper {
    padding: 20px !important;
    width: 80% !important;
  }
  .vc-welcome-div {
    display: flex;
    flex-direction: column;
  }
  .col-50 {
    width: 100%;
  }
}


/* The container */
.check-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 4px;
  border: 2px solid #1D8A50;
}

/* On mouse-over, add a grey background color */
.check-container:hover input ~ .checkmark {
  background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.check-container input:checked ~ .checkmark {
  background-color: #1D8A50;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check-container .checkmark:after {
  left: 4px;
  top: 0px;
  width: 4px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.tabActive {
  width: 50%;
  height: 40px;
  background-color: #075e54;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 2px solid #fff;
  color: #fff;
  text-align: center;
  font-Weight: normal;
  font-Size: 16;
  padding-Top: 0;
  cursor: pointer;
  line-height: 32px;
}

.tab {
  width: 50%;
  height: 40px;
  background-color: #1D8A50;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 2px solid #fff;
  color: #fff;
  text-align: center;
  font-Weight: normal;
  font-Size: 16;
  padding-Top: 0;
  cursor: pointer;
  line-height: 32px;
}
