.chat-window {
    flex: 3;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-right: 1px solid #ddd;
    height: 75vh;
    overflow: auto;
  }
  
  .title {
    text-align: center;
    font-size: 24px;
    padding-bottom: 16px;
    color: #068A50;
    font-weight: 700;
  }
  .messages {
    flex: 1;
    overflow-y: auto;
    padding-bottom: 10px;
  }
  
  .message {
    padding: 5px 0;
  }
  
  .input-container {
    display: flex;
    padding: 10px 0;
  }
  
  input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  button {
    padding: 10px 20px;
    margin-left: 10px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  