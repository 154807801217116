.input {
    height: 40px;
    border: 1px solid gray;
}
.button {
    background-color: #1D8A50;
    height: 40px;
    border-radius: 20px;
    border: 0;
    color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}
.button:hover {
    background-color: #068344
}
.pagination-button {
    background-color: #1D8A50;
    height: 20px;
    border-radius: 4px;
    border: 0;
    color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pagination-select {
    height: 20px;
    border-radius: 4px;
    border: 1px solid gray;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    outline: none;
}
.select-input {
    height: 40px;
    border: 1px solid gray;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    outline: none;
}